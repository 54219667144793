<template>
    <div>
        <v-row align="start" justify="start" dark>
            <v-col cols="12" class="d-flex flex-start">
                <div class="text-h6">Лицензии</div>

                <v-btn color="purple darken-2" class="ml-auto white--text" @click.prevent.stop="dialog.activate = true">
                    <v-icon left dark>
                        mdi-plus
                    </v-icon>
                    <span>Активировать ключ</span>
                </v-btn>
            </v-col>
        </v-row>

        <v-tabs v-model="currentTab" class="mb-2">  
            <v-tab v-for="tab in tabs" :key="tab">{{ tab }}</v-tab>
        </v-tabs>

        <license-list v-if="currentTab === 0" :items="items" />
        <license-statistic v-if="currentTab === 1" :model="statisticModel" />

        <v-dialog v-model="dialog.activate" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card>
                <v-toolbar dark color="purple darken-2">
                    <v-toolbar-title>Активация ключа</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn icon dark @click="dialog.activate = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <div>
                    <register-form :key="dialog.activate" class="elevation-0" @success="onActivateSuccess" />
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { mapState } from 'vuex'
import RegisterForm from '@/components/auth/RegisterForm.vue'
import LicenseList from '@/components/license/List.vue'
import LicenseStatistic from '@/components/license/Statistic.vue'

export default {
    components: { RegisterForm, LicenseList, LicenseStatistic },
    data () {
        return {
            dialog: {
                activate: false
            },
            currentTab: 0
        }
    },
    computed: {
        ...mapState('license', ['items']),
        tabs () {
            return [
                'Список',
                'Статистика'
            ]
        }
    },
    async created () {
        const { data } = await this.$store.dispatch('license/summary')
        this.statisticModel = data
    },
    methods: {
        async onActivateSuccess() {
            await this.$store.dispatch('license/list', { pagination: 0 });
            this.dialog.activate = false;
        }
    }
}
</script>