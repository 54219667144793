<template>
    <div class="d-flex flex-column">

        <div class="d-flex flex-wrap mt-10">
            <v-card class="pa-4 d-flex flex-column mr-5">
                <h4 class="mb-5">Состояние</h4>
                <v-data-table
                    :headers="[
                        { text: 'Параллель', value: 'grade' },
                        { text: 'Групп', value: 'groups' },
                        { text: 'Учеников', value: 'students' },
                    ]"
                    dense
                    :items="groupsItemsStatistic"
                    :items-per-page="100"
                    hide-default-footer
                    class="elevation-1"
                />
            </v-card>

            <v-card class="pa-4 d-flex flex-column">
                <h4 class="mb-5">Лимиты</h4>
                <v-data-table
                    :headers="[
                        { text: 'Категория', value: 'category' },
                        { text: 'Максимальное значение', value: 'value' }
                    ]"
                    dense
                    :items="limitsItems"
                    :items-per-page="100"
                    hide-default-footer
                    class="elevation-1"
                />
            </v-card>
        </div>
        
        <v-card class="pa-4 d-flex flex-column mt-10">
            <h4 class="mb-5">Разблокированные работы</h4>
            <div class="d-flex flex-wrap">
                <v-card
                    v-for="(subject, skey) in model.subjects"
                    :key="skey"
                    class="pa-4 mr-5 mb-5"
                >
                    <v-list-item-title>{{ skey }}</v-list-item-title>
                    <v-list-item
                        v-for="(variants, grade) in subject"
                        :key="grade"
                        two-line
                    >
                        <v-list-item-content>
                            <v-list-item-title>{{ grade }} класс</v-list-item-title>
                            <v-list-item-subtitle v-if="Array.isArray(variants) && variants[0] !== '*'">
                                Вариант: {{ variants.join(', ') }}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle v-else-if="variants === '*'">
                                Все варианты
                            </v-list-item-subtitle>
                            <v-list-item-subtitle v-else>
                                {{ variants }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-card>
            </div>
        </v-card>
    </div>
</template>

<script>
export default {
    props: {
        model: { type: Object, required: true }
    },
    computed: {
        groupsItemsStatistic () {
            return [
                    ...Object.keys(this.model.usage.grade)
                        .map(grade => {
                            return {
                                grade,
                                groups: this.model.usage.grade[grade].groups,
                                students: this.model.usage.grade[grade].students
                            }
                        }),
                        {
                            grade: 'Всего',
                            groups: this.model.usage.total.groups,
                            students: this.model.usage.total.students
                        }
                    ]
        },
        limitsItems () {
            return [
                { category: 'Групп на параллель', value: this.model.params.grade_groups },
                { category: 'Учеников на группу', value: this.model.params.group_students },
                { category: 'Всего учеников', value: this.model.params.total_students }
            ]
        }
    }
}
</script>